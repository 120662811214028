import styled from "styled-components";


export const CardContainer = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    width: 350px;
    height: 100px;
    background: rgb(234,235,135);
    background: linear-gradient(90deg, rgba(234,235,135,1) 33%, rgba(180,247,161,1) 75%, rgba(138,255,255,1) 100%);
    border: .15rem solid black;
    border-radius: 15px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    cursor: pointer;
    &:hover{
        transform: scale(1.2);
    }
    @media screen and (max-width:750px){
        &:hover{
            transform: scale(1);
        }
    }
`
export const InnerContainer = styled.div`
    display: flex;
    flex-direction: row;
    position: relative;
    justify-content: center;
    align-content: center;
    
`
export const SiteName = styled.h1`
    font-size: 30px;
    font-weight: 600;
    text-align: left;
    color: #36454F;
    position: relative;
    padding-top: .25em;
    
`
export const ImageContainer = styled.div`
    width: 100px;
    height: auto;
    position:relative;
    padding: 0;
    margin: 0;
    

`