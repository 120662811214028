import styled from "styled-components";


export const Header =styled.h1`
    display: flex;
    flex-direction: row;
    position: relative;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    font-size: 2rem;
    font-weight: 600;
    font-family: Arial, Helvetica, sans-serif;
    letter-spacing: .05em;
    @media screen and (max-width:750px){
        font-size: 1.2rem;
    }
`;

export const Form = styled.form`
  display: flex;
  position: relative;
  height: 100%;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  gap: 1em;
  margin: 0;
  padding: 0;
`;

export const Input = styled.input`
  position: relative;
  background-color: transparent;
  outline: none;
  border: 2px solid white;
  border-radius: 0.5em;
  color: inherit;
  height: auto;
  width: fit-content;
  margin-left: 1em;
  :-webkit-autofill,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus,
  :-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px inherit inset !important;
    box-shadow: 0 0 0 30px inherit inset !important;
    -webkit-text-fill-color: inherit;
  }
  & + label {
    transform: translate(-1em, 0);
  }

  &:focus + label {
    transform: translate(-1.5em, -1.5em);
    font-size: 0.8rem;
  }
  &:not(:placeholder-shown):not(:focus) + label {
    transform: translate(-1.5em, -1.5em);
    font-size: 0.8rem;
  }
  @media screen and (max-width:750px){
    margin: 0;
    & + label {
    transform: translate(-2em, 0);
  }

  &:focus + label {
    transform: translate(-2.5em, -1.5em);
    font-size: 0.8rem;
  }
  &:not(:placeholder-shown):not(:focus) + label {
    transform: translate(-2.5em, -1.5em);
    font-size: 0.8rem;
  }
        
    }
`;


export const Label = styled.label`
  position: relative;
  color: inherit;
  transition: transform 200ms ease-in-out, font-size 200ms ease-in-out,
    all 200ms ease-in-out;
  font-size: 13px;
  top: -1.16rem;
  left: 2rem;

  &:not([disabled]) {
    cursor: text;
  }
`;


export const FieldContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  
`;

export const TextArea = styled.textarea`
  margin-left: .7em;
  background-color: transparent;
  border: 2px solid inherit;
  color: inherit;
  font-size: 17px;
  outline: none;
  border-radius: 7px;
  transition: padding-left 300ms ease-in-out;
  height: 5em;
  width: 12em;
  resize: none;
  & + label {
    top: -7em;
    left: 1.2em;
    }
    &:focus + label {
        top: -8.5em;
        left: 1em;
        font-size: .8rem;
    }
    &:not(:placeholder-shown):not(:focus) + label {
        top: -8.5em;
        left: 1em;
        font-size: .8rem;
    }
  ::placeholder {
    color: inherit;
    font-size: 16px;
    padding: 0;
    text-align: right;
  }
  &:disabled {
    border: none;
  }
  
  :-webkit-autofill,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus,
  :-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px inherit inset !important;
    box-shadow: 0 0 0 30px inherit inset !important;
    -webkit-text-fill-color: inherit;
  }
  &::-webkit-scrollbar {
    width: 0.7em;
  }
  &::-webkit-scrollbar-track {
    background-color: inherit;
  }
  &::-webkit-scrollbar-thumb {
    background: inherit;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: inherit;
  }
  @media screen and (max-width:750px){
    margin: 0;
    & + label {
    top: -7em;
    left: 0.2em;
    }
    &:focus + label {
        top: -8.5em;
        left: 0em;
        font-size: .8rem;
    }
    &:not(:placeholder-shown):not(:focus) + label {
        top: -8.5em;
        left: 0em;
        font-size: .8rem;
    }
        
    }
`;

