import React, { useState } from "react";
import { FieldContainer, Label,Form,Input,Header, TextArea } from "./StyledComponents";

const ContactForm = () =>{
    const [email,setEmail] = useState("");
    const [content,setContent] = useState("");
    return(
        <Form>
            <Header>Contact Us</Header>
            <FieldContainer>
                <Input 
                id="email"
                name="email"
                placeholder=" "
                value={email}
                onChange={(e)=>setEmail(e.target.value)}
                />
                <Label htmlFor="email">Your Email:</Label>
            </FieldContainer>
            <FieldContainer>
                <TextArea 
                id="content"
                name="content"
                placeholder=" "
                value={content}
                onChange={e=>setContent(e.target.value)}
                />
                <Label htmlFor="content">Message:</Label>
            </FieldContainer>
        </Form>

    )
}
export default ContactForm