import styled from "styled-components";
import { ReactComponent as DBImage } from "../assets/db.svg";
import { ReactComponent as PlatformImage } from "../assets/platforms.svg";
import { ReactComponent as MapImage } from "../assets/mapCurcuit.svg";
import { ReactComponent as MCLogo } from "../assets/MCLogo.svg";

export const MasterContainer=styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    padding:0;
    position: relative;
    justify-content: center;
    align-items: center;
    margin: auto;
    width: 100%;
    height: auto;
    min-height: 100vh;
`;
export const InnerContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0;
    position: relative;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    height: fit-content;
`;
export const DeepInnerContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0;
    position: relative;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: auto;
    height: fit-content;
    @media screen and (max-width:750px){
        justify-content: flex-end;
        align-items: flex-end;
        text-align: right;
        width: 100%;
    }
`;
export const ParagraphContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0;
    position: relative;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: auto;
    height: fit-content;
    box-sizing: border-box;
    padding: 0 5rem 0 5rem;
    @media screen and (max-width:750px){
        justify-content: flex-start;
        align-items: flex-start;
        text-align: left;
        width: 100%;
    }
`;

export const Paragraph = styled.p`
    display: flex;
    flex-direction: row;
    position: relative;
    flex-wrap: wrap;
    padding: 0;
    font-size: 1.5rem;
    font-weight: 600;
    font-family: Arial, Helvetica, sans-serif;
`
export const H1 =styled.h1`
    display: flex;
    flex-direction: row;
    position: relative;
    flex-wrap: wrap;
    padding: 0;
    font-size: 5rem;
    font-weight: 600;
    font-family: Arial, Helvetica, sans-serif;
    letter-spacing: .05em;
    @media screen and (max-width:750px){
        font-size: 3rem;
    }
`;
export const A1 =styled.a`
    display: flex;
    flex-direction: row;
    text-decoration: none;
    position: relative;
    flex-wrap: wrap;
    padding: 0;
    font-size: 5rem;
    font-weight: 600;
    font-family: Arial, Helvetica, sans-serif;
    letter-spacing: .05em;
    @media screen and (max-width:750px){
        font-size: 3rem;
    }
`;

export const H2 =styled.h2`
    display: flex;
    flex-direction: row;
    position: relative;
    flex-wrap: wrap;
    padding: 0;
    font-size: 3rem;
    font-weight: 600;
    font-family: Arial, Helvetica, sans-serif;
    letter-spacing: .05em;
    @media screen and (max-width:750px){
        font-size: 2rem;
    }
`;
export const H3 =styled.h3`
    position: relative;
    font-size: 3rem;
    font-weight: 600;
    font-family: Arial, Helvetica, sans-serif;
    /* letter-spacing: .05em; */
    @media screen and (max-width:750px){
        font-size: 1.5rem;
    }
`;

export const DBSVG = styled(DBImage)`
    padding: 0;
    margin: 0;
    display: block;
    position: relative;
    width: 50%;
    height: auto;
`

export const PlatformSVG = styled(PlatformImage)`
    padding: 0;
    margin: 0;
    position: relative;
    display: block;
    width: 50%;
    height: auto;
    @media screen and (max-width:750px){
        width: 70%;
    }
    
`
export const MapSVG = styled(MapImage)`
    padding: 0;
    display: block;
    position: relative;
    margin: 0;
    width: 70%;
    height: auto;
    @media screen and (max-width:750px){
        width: 100%;
    }
    
`
export const CardContainer =styled.div`
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 1em;
    
`
export const MCSVG = styled(MCLogo)`
    padding: 0;
    margin: 0;
    display: block;
    position: relative;
    width: inherit;
    height: auto;
`