import React from "react";
import Header from "./components/header/Header";
import styled from "styled-components";
import Home from "./pages/Home";
import Footer from "./components/footer/Footer";
import Products from "./pages/Products";
import { Routes, Route } from 'react-router-dom';

const AppContainer = styled.div`
  width: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: #007575 ;
  padding: 0;
  margin: 0;
  /* box-sizing: border-box; */
  
  
`
const TopContainer = styled.div`
  display: flex;
  position: sticky;
  right: 0;
  top: 0;
  left: 0;
  margin: auto;
  width: 95%;
  height: 75px;
  background-color: #00B2B3;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  z-index: 9;
  border-radius: 0 0 25px 25px;
  /* box-sizing: border-box; */
  
`
const MainContainer = styled.div`
  display: flex;
  position: relative;
  width: auto;
  height: 95%;
  min-height: 100vh;
  justify-content: center;
  align-items: center;
  color: #BCECE0;
  margin: auto;
  /* box-sizing: border-box; */
`;

const BotContainer = styled.div`
  display: flex;
  position: relative;
  width: 100vw;
  height: auto;
  background-color: #FF8E9C;
  justify-content: center;
  align-items: center;
  color: #BCECE0;
  /* box-sizing: border-box; */
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
`

function App() {
  return (
    
  <AppContainer>
    <TopContainer>
    <Header/>
    </TopContainer>
    <MainContainer>
      <Routes>
        <Route exact path='/' element={<Home/>}/>
        <Route exact path='/products' element={<Products/>}/>
      </Routes>
    </MainContainer>
    
    <BotContainer>
      <Footer/>
    </BotContainer>
      
    
  </AppContainer>
  );
}

export default App;
