import React, { useState } from "react";
import ContactForm from "../contactForm/ContactForm";
import { A1, A2, H1, H2, InnerContainer, MasterContainer } from "./StyledComponents";

const Footer = () =>{
    const [year] = useState(new Date().getFullYear());
    return(
        <MasterContainer>
            <InnerContainer>
                <ContactForm/>
            </InnerContainer>
            <InnerContainer>
                <A2 href="https://server.zevrex.net/docs">
                    Documentation
                </A2>
                <H2>
                    Copyright Zevrex 2021-{year}
                </H2>
            </InnerContainer>
        </MasterContainer>
    )
}

export default Footer