import React from "react";
import { DBSVG, DeepInnerContainer, H1,H2,H3, InnerContainer, MapSVG, MasterContainer, PlatformSVG } from "./StyledComponents";

const Home=()=>{
    return(
        <MasterContainer>
            <InnerContainer>
                <H1>
                    Software Solutions for Everyone
                </H1>           
            </InnerContainer>
            <InnerContainer>
                <DBSVG />
            </InnerContainer>
            <InnerContainer>
                <H2>
                    Responsive and Intuitive Designs
                </H2>
            </InnerContainer>
            <InnerContainer style={{ justifyContent:'flex-start', textAlign:'right' }}>
                <PlatformSVG />
                <DeepInnerContainer>
                <H3>
                    For all Platforms
                </H3>
                </DeepInnerContainer>
                
            </InnerContainer>
            <InnerContainer>
                <H1>
                    Keeping your Business Conected
                </H1>
            </InnerContainer>
            <InnerContainer>
                <MapSVG/>
            </InnerContainer>            
        </MasterContainer>
    )
}

export default Home