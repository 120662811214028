import styled from "styled-components";
import { ReactComponent as ZevLogo } from "../../assets/zevrexLogo.svg";

export const HeaderContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding-left: 2em;
    padding-right: 2em;
    box-sizing: border-box;
    @media screen and (max-width:750px){
        padding-left: 0;
    }
    
`;

export const ZevrexLogo = styled(ZevLogo)`
    padding: 0;
    margin: 0;
    width: auto;
    height: 100%;
    display: block;
    min-width: 50px;
`;