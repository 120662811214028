import React from "react";
import WebCard from "../components/cards/WebCard";
import { InnerContainer, MasterContainer, CardContainer, MCSVG, H1,  Paragraph, ParagraphContainer } from "./StyledComponents";


const Products=()=>{
    const onClick=()=>{
        window.location.href="https://mc.zevrex.net/";
    }
    return(
        <MasterContainer style={{justifyContent:"start",marginTop:"1em",height:'fit-content'}}>
            <InnerContainer>
                <H1>
                    Manage-Connect is an Advanced Dealer Management System
                </H1>
            </InnerContainer>
            <ParagraphContainer>
                <Paragraph >
                    Enable your customers to have a more transparent, and in turn a more compeling, experiance via Manage-Connect. 
                    Mechanics are able to seemlessly complete jobs while being able to document along the way to protect your business and your customers.
                    Manage your customers and employees all in one application right from your mobile divice or browser.
                    Easily create leads for sales and know what needs to be sold ASAP. 
                    Automatically notify customers when their service is started and complete as well as collect payments right from the convienance of their phone.
                </Paragraph>
            </ParagraphContainer>
            <InnerContainer>
                <CardContainer>
                    <WebCard 
                    onClick={onClick}
                    name="Manage-Connect"
                    img={<MCSVG/>}
                    />
                </CardContainer>                
            </InnerContainer>
        </MasterContainer>
    )
}
export default Products