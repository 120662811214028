import React from "react";
import { useNavigate } from "react-router-dom";
import { Nav, NavContainer } from "./StyledComponents";

const Navigation = () =>{
    let nav = useNavigate();
    const onClick=(dest)=>{
        switch(dest){
            case 'home':
                nav('/');
                break;
            case 'products':
                nav('/products');
                break;
            case 'about':
                break;
            default:
                break;
        }
    }
    return(
        <NavContainer>
            <Nav onClick={()=>onClick('home')}>Home</Nav>
            <Nav onClick={()=>onClick('products')}>Products</Nav>
            <Nav onClick={()=>onClick('about')}>About</Nav>
        </NavContainer>
    )
}

export default Navigation