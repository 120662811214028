import styled from "styled-components";

export const MasterContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    position: relative;
    height: auto;
    width: 100%;
    justify-content: space-around;
    align-items: flex-start;
    overflow-x: hidden;
    margin-top: 1em;
`;

export const InnerContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    position: relative;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    
`;
export const H1 =styled.h1`
    display: flex;
    flex-direction: row;
    position: relative;
    flex-wrap: wrap;
    padding: 0;
    font-size: 2rem;
    font-weight: 600;
    font-family: Arial, Helvetica, sans-serif;
    letter-spacing: .05em;
    @media screen and (max-width:750px){
        font-size: 1.2rem;
    }
`;

export const H2 =styled.h2`
    display: flex;
    flex-direction: row;
    position: relative;
    flex-wrap: wrap;
    padding: 0;
    font-size: 1.2rem;
    font-weight: 600;
    font-family: Arial, Helvetica, sans-serif;
    letter-spacing: .05em;
    @media screen and (max-width:750px){
        font-size: .9rem;
    }
`;
export const A1 =styled.a`
    display: flex;
    text-decoration: none;
    color: #00A3A3;
    flex-direction: row;
    position: relative;
    flex-wrap: wrap;
    padding: 0;
    font-size: 2rem;
    font-weight: 600;
    font-family: Arial, Helvetica, sans-serif;
    letter-spacing: .05em;
    @media screen and (max-width:750px){
        font-size: 1.2rem;
    }
`;

export const A2 =styled.a`
    display: flex;
    text-decoration: none;
    color: #00A3A3;
    flex-direction: row;
    position: relative;
    flex-wrap: wrap;
    padding: 0;
    font-size: 1.2rem;
    font-weight: 600;
    font-family: Arial, Helvetica, sans-serif;
    letter-spacing: .05em;
    @media screen and (max-width:750px){
        font-size: .9rem;
    }
`;