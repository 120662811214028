import React from "react";
import { CardContainer, ImageContainer, InnerContainer, SiteName } from "./StyledComponents";

const WebCard = ({name="empty",img=<></>,onClick=()=>{}}) =>{
    return(
<CardContainer onClick={onClick}>
        <InnerContainer>
            <ImageContainer>
                {img}
            </ImageContainer>
            <SiteName>
                {name}
            </SiteName>
        </InnerContainer>
    </CardContainer>
    )
    
}
export default WebCard