import React from "react";
import Navigation from "../navigation/Navigation";
import { HeaderContainer, ZevrexLogo } from "./StyledComponents";
 
const Header=()=>{
    return(
        <HeaderContainer>
            <ZevrexLogo/>
            <Navigation/>
        </HeaderContainer>
    )
}

export default Header