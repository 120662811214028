import styled from "styled-components";

export const NavContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    gap: 3em;
    position: relative;
    align-items: center;
    @media screen and (max-width:750px){
        gap: 2em;
    }
`

export const Nav =styled.button`
    position: relative;
    border: none;
    outline: none;
    background-color: transparent;
    cursor: pointer;
    text-decoration: none;
    text-align: center;
    color: #4C5270;
    font-size: 20px;
    font-weight: 400;
    font-family: Arial, Helvetica, sans-serif;
    -webkit-text-stroke-width: .5px;
    -webkit-text-stroke-color: black;
`